import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import vSelect from "vue-select";
// import VueGtag from "vue-gtag";

Vue.component("v-select", vSelect);

import ButtonBack from "./components/ButtonBack";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import VueClipboard from "vue-clipboard2";
import moment from "moment";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import "./assets/scss/styles.scss";
import "vue-select/dist/vue-select.css";

let appLoading = Vue.observable({ value: false });
Object.defineProperty(Vue.prototype, "$appLoading", {
  get() {
    return appLoading.value;
  },

  set(value) {
    appLoading.value = value;
  }
});

// axios
import axios from "./http/axios/index.js";
Vue.prototype.$http = axios;

import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;
Vue.use(BootstrapVue);
Vue.use(VueClipboard);
Vue.use(IconsPlugin);
Vue.use(flatPickr);
Vue.use(moment);
// Vue.use(VueGtag, {
//   config: { id: "G-TSHB0CJDHS" }
// });
Vue.component("button-back", ButtonBack);

router.beforeEach((to, from, next) => {
  const userInfo = localStorage.getItem("userInfo");
  const loginPage = to.matched.some(record => record.meta.authPage);

  if (!loginPage && !userInfo) {
    next("/login");
  }

  if (!loginPage && from.name != to.name) {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch("verify_auth")])
      .then(next)
      .catch(err => {
        err;
        next("/login");
      });

    return;
  }

  next();
});

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    let vm = this;
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const userData = JSON.parse(userInfo);
      vm.$store.commit("setUserData", userData);
      // vm.$acl.change(userData.permissions);
    }

    axios.interceptors.request.use(
      config => {
        vm.$appLoading = true;
        return config;
      },
      error => {
        vm.$appLoading = false;
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        vm.$appLoading = false;
        let expires = 86400 + new Date().getTime() / 1000; //seconds
        localStorage.setItem("expires_in", expires);
        return response;
      },
      error => {
        if (error.response.status === 401) {
          vm.$store.dispatch("logout");
          window.location.reload();
        }
        vm.$appLoading = false;
        return Promise.reject(error);
      }
    );
  }
}).$mount("#app");

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});
